<template>
  <v-overlay :value="overlay.show" opacity=".9" class="text-center">
    <p v-html="overlay.message"></p>
    <v-btn small color="primary" v-if="overlay.button" @click.stop="retry()">Retry</v-btn>
  </v-overlay>
</template>

<script>
export default {
  computed: {
    overlay: {
      get() {
        return this.$store.state.overlay;
      },
      set(val) {
        val || this.$store.dispatch("overlayHide");
      }
    }
  },
  methods: {
    retry() {
      window.location.reload();
    }
  }
};
</script>