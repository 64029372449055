<template>
  <div class="pa-1 pa-sm-0  ma-0 ">
    <div class="my-2 ma-md-3 ">
      <v-select
        v-model="filter"
        :items="filters"
        label="Filter"
        hide-details
        class="mb-4"
        @change="filterLists"
        :loading="loading"
        :disabled="loading"
      ></v-select>
    </div>
    <div v-if="hasList">
      <!-- <v-divider color="#333"></v-divider> -->
      <v-layout
        class="list-history py-2 pa-md-3"
        :class="withdraw.status + '-bg-status'"
        v-for="(withdraw, index) in lists"
        :key="index"
      >
        <v-flex class="text-left px-1" :class="[$vuetify.breakpoint.mdAndUp ? 'h6' : 'caption']">
          {{ formatMoney(parseFloat(withdraw.amount)) }}
          <div class="with-info dep-info">
            <span v-if="withdraw.status === 'processing'" class="animated flash infinite">Waiting</span>
            <span
              v-else-if="withdraw.status === 'need-replenish'"
              class="animated flash infinite"
            >Replenish</span>
            <span v-else>{{ withdraw.status === 'refunded' ? 'Cancelled' : withdraw.status_label }}</span>
          </div>
        </v-flex>
        <v-flex xs4 class="text-left" :class="[$vuetify.breakpoint.mdAndUp ? 'h6' : 'caption']">
          <div>{{ withdraw.user.mobile }}</div>
          <div class="with-info dep-info">{{ withdraw.user.name }}</div>
        </v-flex>
        <v-flex xs3 class="text-right" v-if="withdraw.status === 'processing'">
          <v-btn icon @click="cancel(withdraw.id)" class="mr-0 mr-sm-2 mt-1">
            <v-icon class="red--text">mdi-close</v-icon>
          </v-btn>
          <v-btn icon @click="openRequest(withdraw.id, withdraw.user, withdraw.amount)" class="mt-1">
            <img
              src="./../../../public/imgs/hand-holding-usd-solid.svg"
              width="18px"
              style="color: yellow"
            />
          </v-btn>
        </v-flex>
        <v-flex xs4 class="text-right text-sm-caption px-1" v-else>
          {{ withdraw.date_label }}
          <div class="with-info dep-info">{{ withdraw.time_label }}</div>
        </v-flex>
      </v-layout>
    </div>
    <div v-else-if="lists < 1" class="text-center ma-3 text-lose">
      <small>{{ loading ? 'Loading...' : filter !== 'All' ? `No ${filter.toLowerCase()} withdrawals were made yet` : 'No withdrawals were made yet' }}</small>
    </div>
    <div v-if="hasMore" class="my-3 text-center">
      <v-btn text small :loading="loading" @click.stop.prevent="loadMore()">Show More</v-btn>
    </div>
    <v-dialog v-if="request.id" light v-model="cancelModal" max-width="300">
      <v-card color="light-blue lighten-5 ">
        <div class="d-flex align-center py-3 px-3 elevation-0 light-blue darken-2">
          <strong class="align-self-center  grey--text text--lighten-3">CONFIRM</strong>
          <v-spacer></v-spacer>
          <v-icon class="header-close  grey--text text--lighten-3" @click.stop="cancelModal = false">mdi-close</v-icon>
        </div>
        <v-card-text class="pa-3 mb-3">Cancel withdrawal?</v-card-text>
        <div class="d-flex  pa-2">
          <div class="flex-grow-1 mx-1">
            <v-btn
              class="rounded "
              elevation="1"
              block
              color="grey lighten-2"
              light
              @click.stop="cancelModal = false"
            >NO</v-btn>
          </div>
          <div class="flex-grow-1 mx-1">
            <v-btn
              class=" white--text rounded "
              elevation="1"
              block
              color="light-blue darken-3"
              :disabled="busy"
              :loading="busy || loading"
              @click.stop="cancelRequest()"
            >YES</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-if="request.user && request.user.mobile" light v-model="openModal" max-width="300">
    <v-card color="light-blue lighten-5">
        <div class="d-flex align-center py-3 px-3 elevation-0 light-blue darken-2">
          <strong class="align-self-center  grey--text text--lighten-3">WITHDRAW</strong>
          <v-spacer></v-spacer>
          <v-icon class="header-close  grey--text text--lighten-3" @click="openModal = false">mdi-close</v-icon>
        </div>
        <v-card-text class="pa-3">
          <v-form v-model="valid" lazy-validation ref="form" @submit="sendRequest()">
            <div>
              Requested by:
              <strong class="black--text">{{ request.user.name }} ({{ request.user.mobile }})</strong>
            </div>
            <div>
              Amount:
              <strong class="black--text">{{ request.amount }}</strong>
            </div>
            <v-text-field
              v-model="request.ref_num"
              label="Reference #"
              :rules="[rules.required]"
              :error="!!error"
              :error-messages="error && error.ref_num ? error.ref_num[0] : ''"
              :disabled="busy"
              @keyup.enter="sendRequest()"
              required
              clearable
            ></v-text-field>
          </v-form>
        </v-card-text>
        <div class="d-flex pa-2">
          <!--<div class="flex-grow-1">-->
            <!--<v-btn-->
              <!--class="modal-btn elevation-0"-->
              <!--style="border-radius: 0"-->
              <!--block-->
              <!--color="grey lighten-2"-->
              <!--light-->
              <!--@click="openModal = false"-->
            <!--&gt;Close</v-btn>-->
          <!--</div>-->
          <div class="flex-grow-1">
            <v-btn
              class="white--text rounded"
              elevation="1"
              block
              color="light-blue darken-3"
              :disabled="busy"
              :loading="busy || loading"
              @click="sendRequest()"
            >Complete</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import mixin from "./../../mixins";
import list from "./../../mixins/list";
import helpers from "./../../mixins/helpers";
import rules from "./../../mixins/rules";

export default {
  mixins: [mixin, list, helpers, rules],
  data: () => ({
    dataUrl: "/reseller/withdrawals",
    filter: "All",
    request: {
      id: null,
      user: null,
      amount: null,
      ref_num: null
    },
    openModal: false,
    cancelModal: false,
    valid: true,
    filters: ["All", "Pending", "Completed", "Cancelled"]
  }),
  methods: {
    openRequest(id, user, amount) {
      this.request.id = id;
      this.request.user = user;
      this.request.amount = amount;
      this.openModal = true;
    },
    cancel(id) {
      this.request.id = id;
      this.cancelModal = true;
    },
    sendRequest() {
      if (this.$refs.form.validate()) {
        let data = {
          id: this.request.id,
          ref_num: this.request.ref_num
        };
        this.postData(`/reseller/withdrawals/complete`, data, res => {
          this.$store.dispatch("prompt", {
            message: res.data ? res.data.message : null
          });
          this.openModal = false;
          this.reset();
          this.getList();
        });
      }
    },
    cancelRequest() {
      let data = {
        id: this.request.id
      };
      this.postData(`/reseller/withdrawals/cancel`, data, res => {
        this.$store.dispatch("snackbar", {
          message: res.data ? res.data.message : null
        });
        this.cancelModal = false;
        this.reset();
        this.getList();
      });
    },
    filterLists(filter) {
      this.params = `&filter=${filter.toLowerCase()}`;
      this.getList();
    }
  }
};
</script>

<style lang="sass">
.completed-bg-status
  background-color: #2E7D32 !important
  border-bottom: 1px solid #1B5E20 !important

.cancelled-bg-status, .refunded-bg-status
  background-color: #C62828 !important
  border-bottom: 1px solid #B71C1C !important

.pending-bg-status
  background-color: #2F2F2F !important
  border-bottom: 1px solid #222222 !important
</style>
