<template>
    <v-fab-transition>
      <v-btn
        color="orange lighten-1"
        fab
        large
        dark
        bottom
        right
        fixed
        class="fab-btn"
        style="border: 2px solid orange"
        @click="open()"
      >
      <v-icon>mdi-chat</v-icon>
        <!-- <img src="./../../public/imgs/rep.jpeg" width="100%" /> -->
      </v-btn>
    </v-fab-transition>
</template>

<script>
export default {
    methods: {
        open() {
            window.open('https://www.messenger.com/t/jhasmineufc' ,'_system')
        }
    }
}
</script>