<template>
  <div class="pa-1 pa-sm-0 ma-0" >
    <v-layout class="list-history py-2 pa-md-3" v-for="(downline, index) in lists" :key="index">
      <v-flex class="text-left">
        <div>{{ downline.mobile }}</div>
        <div class="with-info grey--text">{{ downline.name }}</div>
      </v-flex>
      <v-flex class="text-right">
        <span class="comm-amount mt-2 ">{{ downline.mtd_amount }}</span>
        <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="sendCreditsModal">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mt-0 ml-2" icon v-bind="attrs" v-on="on">
              <v-icon color="#ffc425" >mdi-telegram</v-icon>
            </v-btn>
          </template>
          <template>
            <send-credits :downlineMobile="downline.mobile" @close="closeSendCredits"></send-credits>
          </template>
        </v-dialog>
      </v-flex>
    </v-layout>
    <div v-if="hasMore" class="my-3 text-center">
      <v-btn text small :loading="loading" @click.stop.prevent="loadMore()">Show More</v-btn>
    </div>
  </div>
</template>

<script>
import mixin from "./../../mixins";
import list from "./../../mixins/list";
import helpers from "./../../mixins/helpers";
import SendCredits from "./SendCredits";

export default {
  mixins: [mixin, list, helpers],
  components: {
      SendCredits,
  },
  data: () => ({
    dataUrl: "/reseller/downlines",
    sendCreditsModal: false,
    reseller: null
  }),
  methods: {
    closeSendCredits(){
      this.sendCreditsModal = false
    },
    send(number) {
      this.$router.push({ path: `send/${number}` });
    }
  }
};
</script>
