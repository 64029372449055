<template>
  <div class="pa-1 pa-sm-0  ma-0" >
    <div v-if="hasList">
      <v-layout
        class="list-history py-2 pa-md-3"
        :class="history.status + '-bg-status'"
        v-for="(history, index) in lists"
        :key="index"
      >
        <v-flex class="text-left ">
            <span class="amber--text" :class="[$vuetify.breakpoint.mdAndUp ? 'h6' : 'caption']">
              {{ formatMoney(parseFloat(history.amount)) }}
            </span>
          <div class="with-info dep-info grey--text">
            <span >{{ history.reseller_type_label }}</span>
          </div>
        </v-flex>
        <v-flex xs4 class="text-left">
          <template v-if="history.type === 'replenish'">
            <div v-if="history.notes">
              Paid by: {{ history.notes }}
            </div>
          </template>
          <template v-else-if="history.user2">
            <div :class="[$vuetify.breakpoint.mdAndUp ? 'h6' : 'caption']">{{ history.user2.masked_email }}</div>
            <div class="with-info dep-info" :class="[$vuetify.breakpoint.mdAndUp ? 'h6' : 'caption']">{{ history.user2.name }}</div>
          </template>
          <template v-if="history.type === 'claimed-commission'">
            Claimed Commission
          </template>
        </v-flex>
        <v-flex xs4 class="text-right" :class="[$vuetify.breakpoint.mdAndUp ? 'h6' : 'caption']">
          {{ history.date_label }}
          <div class="with-info dep-info grey--text">{{ history.time_label }}</div>
        </v-flex>
      </v-layout>
    </div>
    <div v-else-if="lists < 1" class="text-center ma-3 text-lose">
      <small>{{ loading ? 'Loading...' : 'No transaction history yet' }}</small>
    </div>
    <div v-if="hasMore" class="my-3 text-center">
      <v-btn text small :loading="loading" @click.stop.prevent="loadMore()">Show More</v-btn>
    </div>
  </div>
</template>

<script>
import mixin from "./../../mixins";
import list from "./../../mixins/list";
import helpers from "./../../mixins/helpers";

export default {
  mixins: [mixin, list, helpers],
  data: () => ({
    dataUrl: "/reseller/transfers/history"
  })
};
</script>
