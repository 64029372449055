<template>
  <div>
    <v-dialog light v-model="commRateModal.show" max-width="300">
      <v-card class="wallet-modal">
        <div class="pa-3 text-center">Commission rates</div>
        <v-simple-table dense class="mb-3">
        <div v-if="busy" class="text-center">
        <v-progress-circular
          class="loading-circle text-center"
          size="24"
          indeterminate
        />
        </div>
        <template v-else>
        <thead>
        <th class="text-center">Arena Name</th>
        <th class="text-center">Commission Rate</th>
        </thead>
        <tbody>
        <tr v-for="(item, index) in commRates" :key="index">
        <td class="text-center">{{ item.client_name }}</td>
        <td class="text-center">{{ item.comm_rate }}%</td>
        </tr>
        </tbody>
        </template>
        </v-simple-table>
        <!-- <div class="ma-1 pa-2" v-for="(item, index) in commRates" :key="index">
        <span>{{ item.client_name }}</span>
        <span>{{ item.comm_rate }}%</span>
        </div> -->
        <v-btn
          class="modal-btn elevation-0"
          block
          color="grey lighten-2"
          light
          @click="$store.dispatch('hideCommRateModal')"
        >
          Close
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    commRates: null,
    busy: false,
  }),
  computed: {
    canShare() {
      return navigator.canShare;
    },
    user() {
      return this.$store.state.user;
    },
    commRateModal: {
      get() {
        return this.$store.state.commRateModal;
      },
      set(val) {
        val || this.$store.dispatch("hideCommRateModal");
      },
    }, 
    getCommRateId: {
      get() {
        return this.$store.state.getCommRateId;
      },
    },
  },
  methods: {
    getCommRates() {
	this.busy = true;
	window.axios
		.post(`${window.SETTINGS.API_URL}/reseller/downlines/commrate`, this.getCommRateId)
		.then(response => {
			this.commRates = response.data;
			console.log(this.commRates);
		})
		.catch(error => {
			if (error && error.response)
				error.response.data.errors || error.response.data.message;
		})
		.finally(() => {
			this.busy = false;
		});
    }
  },
  watch: {
    "commRateModal.show"() {
      if (this.user && this.commRateModal.show) this.getCommRates();
    },
  },
};
</script>

<style lang="sass">
.invitation-link
    border: 1px solid #2196f3
    background: 1px solid #eee !important
    color: #333 !important
    font-size: .8em
    border-radius: 4px
    width: 100%
</style>