<template>
  <div>
    <v-dialog light v-model="qrModal.show" max-width="300">
      <v-card  class="wallet-modal" color="light-blue lighten-5">
        <div class="mb-4 mt-1 text-center outlet-details light-blue darken-2 pa-3 d-flex justify-end">
          <v-icon class="header-close white--text" @click="qrModal.show = false">mdi-close</v-icon>
        </div>
        <div class="px-3 light-blue--text text--darken-4 pb-3 text-center">Use this link to recruit downlines</div>
        <div class="mx-auto text-center mb-3" style="width: 256px">
          <div id="qrcode" ref="qr"></div>
        </div>
        <div class="ma-1 pa-2">
          <input
            id="input-link"
            class="invitation-link px-1 py-2"
            :value="user.inv_link"
          />
        </div>
        <v-card  class="pa-2 pb-3" flat color="light-blue lighten-5">
          <v-btn block color="light-blue darken-3" class="mb-2  white--text" light @click="copyLink()">
            Copy Link
          </v-btn>
          <v-btn block v-if="qr" color="light-blue darken-3" class="mb-2 white--text" light @click.stop.prevent="downloadQR()">
            Download QR
          </v-btn>
          <v-btn
            v-if="canShare"
            class=" white--text"
            block
            color="light-blue darken-3"
            light
            @click="shareLink()"
          >
            Share
          </v-btn>
        </v-card>
        <!--<v-btn-->
          <!--class="modal-btn elevation-0"-->
          <!--block-->
          <!--color="grey lighten-2"-->
          <!--light-->
          <!--@click="$store.dispatch('hideQRModal')"-->
        <!--&gt;-->
          <!--Close-->
        <!--</v-btn>-->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    qr: null,
  }),
  computed: {
    canShare() {
      return navigator.canShare;
    },
    user() {
      return this.$store.state.user;
    },
    qrModal: {
      get() {
        return this.$store.state.qrModal;
      },
      set(val) {
        val || this.$store.dispatch("hideQRModal");
      },
    },
  },
  methods: {
    showQR() {
      this.$nextTick(() => {
        let el = document.getElementById("qrcode");
        el.innerHTML = "";
        // eslint-disable-next-line no-undef
        new QRCode(el, this.user.inv_link);
        setTimeout(() => {
            this.qr = this.getImage()
        }, 1000);
      });
    },
    copyLink() {
      let copyText = document.getElementById("input-link");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.$store.dispatch("snackbar", {
        message: "Link copied to clipboard",
        color: "primary",
      });
    },
    downloadQR() {
       // eslint-disable-next-line no-undef
       download(this.qr, "UltimateFightingCockInvitationQR.png", "image/png");
    },
    downloadBase64File(contentBase64, fileName) {
      // const linkSource = `data:application/pdf;base64,${contentBase64}`;
      const linkSource = contentBase64;
      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);

      downloadLink.href = linkSource;
      downloadLink.target = "_self";
      downloadLink.download = fileName;
      downloadLink.click();
    },
    getImage() {
        let qr = this.$refs['qr'].getElementsByTagName('img')[0] ? this.$refs['qr'].getElementsByTagName('img')[0].getAttribute("src") : null;
        return qr
    },
    shareLink() {
      if (navigator.canShare) {
        navigator
          .share({
            title: "Color Fun Game Invitation",
            text: this.user.inv_link,
          })
          .then(() => console.log("Share was successful."))
          .catch((error) => console.log("Sharing failed", error));
      } else {
        console.log(`Your system doesn't support sharing files.`);
      }
    },
  },
  watch: {
    "qrModal.show"() {
      if (this.user && this.qrModal.show) this.showQR();
    },
  },
};
</script>

<style lang="sass">
.invitation-link
    border: 1px solid #2196f3
    background: 1px solid #eee !important
    color: #333 !important
    font-size: .8em
    border-radius: 4px
    width: 100%
</style>
