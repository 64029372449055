<template>
    <v-container v-if="reseller && reseller.user" fluid class="pa-0">
        <v-row class="ma-sm-10 ma-1" >
            <v-col cols="12" md="12" lg="2" class="pa-1 pa-md-2">
                <v-card v-if="user">
                    <v-sheet color="#0288D1" light class="border-radius-0 elevation-0 d-flex justify-space-between px-3 py-2">
                        <div>
                            <v-list-item-title class="title white--text">{{ user.name || null }}</v-list-item-title>
                            <v-list-item-subtitle class="amber--text text--lighten-1 font-weight-bold">{{ user.mobile || null }}</v-list-item-subtitle>
                        </div>
                        <div class="my-auto">
                            <v-dialog transition="dialog-bottom-transition" max-width="400" v-model="showSettings">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn fab dark small color="light-blue darken-4" v-bind="attrs" v-on="on" elevation="2">
                                        <v-icon dark>
                                            mdi-cog
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <template>
                                    <settings @close="closeSettings"></settings>
                                </template>
                            </v-dialog>
                        </div>
                    </v-sheet>
                    <v-sheet color="#3b93d7" light class="border-radius-0 elevation-0  px-3 py-2">
                        <small class="white--text">
                            Commissions:
                            <span class="amber--text text--lighten-1 font-weight-bold">{{ reseller.commission_display }}</span>
                        </small>
                        <div v-if="reseller.has_upline">
                            <small class="white--text">
                                {{ reseller.upline_label }}:
                                <span class="amber--text text--lighten-1 font-weight-bold">{{ reseller.upline_display }}</span>
                            </small>
                        </div>
                    </v-sheet>
                    <v-sheet color="#81b8f3" light class="border-radius-0 elevation-0">
                        <div class="dashboard">
                            <div class="pa-3 pt-2 text-center">
                                <h1 class="blue--text text--darken-4" >
                                    {{ formatMoney(reseller.coordinator.running_commission_balance) }}
                                </h1>
                                <div style="line-height: 1em">
                                    <small>
                                        Unpaid Commissions
                                    </small>
                                </div>
                            </div>
                            <div class="pa-3 pt-2 text-center">
                                <h1 class="blue--text text--darken-4">{{ formatMoney(reseller.coordinator.mtd_commission) }}</h1>
                                <div style="line-height: 1em">
                                    <small>
                                        Commission
                                        <br />(Month to Date)
                                    </small>
                                </div>
                            </div>
                        </div>
                    </v-sheet>
                    <v-sheet color="#3b93d7" light class="border-radius-0 elevation-0 px-3 py-2 white--text">
                        <small class="text-center">
                            <p class="mb-0">Commission Computed at</p>
                            <p>{{ reseller.coordinator.last_recomputed_at }}</p>
                        </small>
                    </v-sheet>
                </v-card>
                <v-container class="mt-5 d-flex justify-space-between pa-0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn large class="px-12 light-blue darken-3" v-on="on" @click="sendCredits = !sendCredits">
                                <v-icon class="px-2">mdi-telegram</v-icon>
                            </v-btn>
                        </template>
                        <span>Transfer Credits</span>
                    </v-tooltip>
                    <v-dialog v-model="sendCredits" transition="dialog-bottom-transition" max-width="600">
                        <template>
                            <send-credits @close="closeSendCredits"></send-credits>
                        </template>
                    </v-dialog>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn  @click.stop="showQR()" large class="px-12 light-blue darken-3" v-bind="attrs"
                                   v-on="on">
                                <v-icon class="px-2">mdi-share-variant</v-icon>
                            </v-btn>
                        </template>
                        <span>Invite Link</span>
                    </v-tooltip>
                </v-container>
            </v-col>
            <v-col cols="12" md="12" lg="10" class="pa-1 pa-md-2" >
                <v-card color="#121831" class="pa-md-2" outlined >
                    <v-tabs background-color="light-blue darken-2" class="pa-2 rounded" tile elevation="2" show-arrows>
                        <v-tabs-slider color="#FFC107"></v-tabs-slider>
                        <v-tab href="#subcoors" v-if="reseller.user.type == 'main_coor'"><span class="white--text" >Subcoors</span></v-tab>
                        <v-tab href="#downlines"><span class="white--text" >Downlines</span></v-tab>
                        <v-tab href="#transfers"><span class="white--text" >Transfers</span></v-tab>
                        <v-tab href="#withdrawals"><span class="white--text" >Withdrawals</span></v-tab>
                        <v-tab-item value="subcoors" >
                            <subcoors :subcoors="reseller.subcoors"></subcoors>
                        </v-tab-item>
                        <v-tab-item value="downlines" >
                            <downlines></downlines>
                        </v-tab-item>
                        <v-tab-item value="transfers">
                            <history></history>
                        </v-tab-item>
                        <v-tab-item value="withdrawals">
                            <withdrawals></withdrawals>
                        </v-tab-item>
                    </v-tabs>
                </v-card>
            </v-col>
        </v-row>
        <q-r-code/>
    </v-container>
</template>
<script>
    import mixin from "./../../mixins";
    import list from "./../../mixins/list";
    import helpers from "./../../mixins/helpers";
    import rules from "./../../mixins/rules";

    import History from './History.vue'
    import Subcoors from './Subcoors.vue'
    import Downlines from './Downlines.vue'
    import Withdrawals from './Withdrawals.vue'
    import Settings from "../Settings";
    import SendCredits from "./SendCredits";
    import QRCode from "../../modals/QRCode";


    export default {
        mixins: [mixin, list, helpers, rules],
        components: {
            QRCode,
            SendCredits,
            Settings,
            History,
            Subcoors,
            Downlines,
            Withdrawals
        },
        computed: {
            user() {
                return this.$store.state.user;
            },
        },
        methods: {
            closeSettings(){
                this.showSettings = false
            },
            closeSendCredits(){
                this.sendCredits= false
            },
            showQR() {
                this.$store.dispatch("showQRModal");
            },
        },
        data: () => ({
            // dataUrl: "/reseller/downlines",
            reseller: null,
            sendCredits: false,
            showSettings: false
        }),
        mounted() {
            this.getReseller((data) => {
                this.reseller = data;
            });
        },
    };
</script>

<style lang="sass">
    .theme--dark.v-tabs-items
        background-color: #121831 !important

</style>
