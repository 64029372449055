export default {
    methods: {
        getUserProfile(callback) {
            this.getData(`/reseller/account`, response => {
                let data = response.data
                this.$store.dispatch('setUser', data)
                localStorage.setItem('user', JSON.stringify(data))
                if (callback) callback()
            }, () => {
                window.location.reload()
            })
        },
        getReseller(callback) {
            this.getData(`/reseller`, response => {
                callback(response)
            })
        },
        getRecent(callback) {
            this.getData(`/reseller/transfers`, response => {
                callback(response)
            })
        },
        getSICredits(mobile, callback) {
            this.getData(`/cfg/sabong-int/account/${mobile}`, response => {
                if (callback) callback(response)
            })
        },
        getWalletHistory(mode, page = 1, newData = false) {
            this.getData(`/reseller/${mode}?limit=2&page=${page}`, response => {
                response['mode'] = mode
                if (newData) response['new_data'] = true
            })
        }
    }
}