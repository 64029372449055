<template>
  <div class="pa-1 pa-sm-0 ma-0" >
    <v-layout class="list-history py-2 pa-md-3" v-for="(subcoor, index) in subcoors" :key="index">
      <v-flex class="text-left">
        <div>
        {{ subcoor.name }}
        <v-btn x-small elevation="3" icon>
            <v-icon color="amber" @click.stop="showCommissionRate(subcoor.id)">mdi-information-outline</v-icon>
          </v-btn>
        </div>
        <div class="with-info grey--text">
            <div class="white--text">{{ subcoor.mobile }}</div>
            Credits:
            <span class="amber--text">{{ formatMoney(subcoor.credits) }}</span>
            <br />Comm Bal:
            <span class="amber--text">{{ formatMoney(subcoor.running_commission_balance) }}</span>
            <div>MTD Comm:<span class="amber--text mt-2 "> {{ formatMoney(subcoor.mtd_commission) }}</span></div>
        </div>
      </v-flex>
      <v-flex class="text-right">
        <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="sendCreditsModal">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mt-0 ml-2" icon v-bind="attrs" v-on="on">
              <v-icon large color="#ffc425" >mdi-telegram</v-icon>
            </v-btn>
          </template>
          <template>
            <send-credits :downlineMobile="subcoor.mobile" @close="closeSendCredits"></send-credits>
          </template>
        </v-dialog>
      </v-flex>
    </v-layout>
    <div v-if="hasMore" class="my-3 text-center">
      <v-btn text small :loading="loading" @click.stop.prevent="loadMore()">Show More</v-btn>
    </div>
  </div>
</template>

<script>
import mixin from "./../../mixins";
import list from "./../../mixins/list";
import helpers from "./../../mixins/helpers";
import SendCredits from "./SendCredits";

export default {
  mixins: [mixin, list, helpers],
  components: {
      SendCredits,
  },
  props: {
    subcoors: {
      default: null,
      required: true
    }
  },
  data: () => ({
    sendCreditsModal: false,
    reseller: null
  }),
  methods: {
    closeSendCredits(){
      this.sendCreditsModal = false
    },
    send(number) {
      this.$router.push({ path: `send/${number}` });
    },
    showCommissionRate(id) {
      this.$store.dispatch("showCommRateModal", { id: id });
    }
  }
};
</script>
