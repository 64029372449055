<template>
  <v-container fluid class="fill-height auth-cont pa-sm-2 pa-0" >
    <v-responsive max-width="500" class="ma-auto px-0 px-md-4" justify="center">
      <v-card flat class="pa-md-4" color="transparent" >
        <v-form ref="form" v-model="valid" lazy-validation class="main-form login-form" @submit="submit()">
          <div class="pt-2 d-flex justify-center">
            <v-img src="./../assets/cfu.png" class="login-logo" contain
                   max-height="300"
                   max-width="300"/>
          </div>
          <v-alert :color="'red'" class="my-4 mb-6" v-if="error && error.mobile">
            <small>{{ error.mobile[0] }}</small>
          </v-alert>
          <v-card  class="pa-5 mt-n7" color="rgb(255,255,255, 0.4)" outlined>
            <v-text-field
                    light
                    v-model="credentials.mobile"
                    label="Mobile Number"
                    type="text"
                    :rules="[rules.required]"
                    :error="!!error"
                    background-color="transparent"
                    required
            ></v-text-field>
            <v-text-field
                    light
                    background-color="transparent"
                    v-model="credentials.password"
                    label="Password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    :rules="[rules.required]"
                    :error="!!error"
                    @click:append="show = !show"
                    @keypress.enter="submit()"
                    autocomplete="new-password"
                    required
            ></v-text-field>
            <div class="text-center mt-2">
              <v-btn color="#113d66" class="mb-3 grey--text text--lighten-2" block @click="submit()" :loading="busy">Login</v-btn>
              <v-btn class="grey--text text--lighten-3"
                      block
                      text
                      @click.stop="$router.push({ name: 'forgot-password' })"
              >Forgot Password</v-btn>
            </div>
          </v-card>
          <div class="text-center my-3 grey--text text--lighten-1">
            <span class="overline">Powered by Cock Fighters Unite</span>
          </div>
        </v-form>
      </v-card>
    </v-responsive>
  </v-container>
</template>

<script>
    import mixin from "./../mixins";
    import auth from "./../mixins/auth";
    import rules from "./../mixins/rules";

    export default {
        mixins: [mixin, auth, rules],
        data: () => ({
            valid: true,
            show: false,
            credentials: {
                mobile: localStorage.getItem("mobile"),
                password: null,
                guid: null
            }
        }),
        methods: {
            submit() {
                if (this.$refs.form.validate()) this.login(this.credentials);
            }
        },
        mounted() {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            window.axios.defaults.headers.common["Authorization"] = null;
            if (this.urlParams)
                this.credentials.guid = this.urlParams.get("guid") || null;
        }
    };
</script>
