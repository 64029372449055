<template>
  <div>
    <v-dialog light v-model="walletModal.show" max-width="300">
      <v-card class="wallet-modal">
        <div class="d-flex pa-3">
          <strong>Add Credits From SI</strong>
        </div>
        <v-divider class="ma-0 pa-0"></v-divider>
        <div class="pa-3 text-center">
          <p>Available SI Credits: <strong>{{ formatMoney(siCredits) }}</strong></p>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit="withdrawSI()"
        >
            <v-text-field
            label="Amount"
            type="number"
            placeholder="0"
            v-model="withdraw.amount"
            :rules="[rules.required, depositAmountRule, creditsRule]"
            :error-messages="error && error.amount ? error.amount[0] : ''"
            :loading="busy"
            />
        </v-form>
        </div>
        <div class="d-flex">
          <div class="flex-grow-1">
            <v-btn
              class="modal-btn no-radius-bottom-right elevation-0"
              block
              color="grey lighten-2"
              light
              @click='$store.dispatch("hideWalletModal")'
            >Close</v-btn>
          </div>
          <div class="flex-grow-1">
            <v-btn
              class="modal-btn no-radius-bottom-left elevation-0"
              block
              color="primary"
              :disabled="busy"
              :loading="busy"
              @click="withdrawSI()"
            >Add</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import data from "./../mixins/data.js";
import fetchers from "./../mixins/fetchers.js";
import helpers from "./../mixins/helpers.js";
import rules from "./../mixins/rules.js";

export default {
  mixins: [data, fetchers, helpers, rules],
  data: () => ({
    withdraw: {
      amount: 500,
      outlet: 'si',
    },
    siCredits: 0.00,
    error: false,
    busy: false,
    valid: true,
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
    walletModal: {
      get() {
        return this.$store.state.walletModal;
      },
      set(val) {
        val || this.$store.dispatch("hideWalletModal");
      }
    },
    depositAmountRule() {
        return this.withdraw.amount >= 500 || `Minimum amount is 500`
    },
    creditsRule() {
      return (
        this.withdraw.amount <= parseFloat(this.siCredits) ||
        `You only have ${this.formatMoney(this.siCredits)} SI credits`
      );
    },
  },
  methods: {
    reset() {
      this.$nextTick(() => {
        this.$refs.form.reset()
      })
    },
    withdrawSI() {
      if (this.$refs.form.validate()) {
        this.busy = true;
        window.axios
          .post(`${window.SETTINGS.API_URL}/cfg/wallet/deposits/si`, this.withdraw)
          .then(response => {
            this.reset();
            this.$store.dispatch("hideWalletModal");
            this.$store.dispatch("prompt", { message: response.data.message });
          })
          .catch(error => {
            if (error && error.response)
              this.error =
                error.response.data.errors || error.response.data.message;
          })
          .finally(() => {
            this.busy = false;
          });
      }
    }, 
    getCredits() {
      this.getSICredits(this.user.mobile, response => {
        this.siCredits = response
      })
    },
  },
  watch: {
    'walletModal.show'() {
      if (this.user && this.user.mobile && this.walletModal.show) this.getCredits()
    }
  }
};
</script>

<style lang="sass">
.modal-btn
  border-radius: 0

</style>