<template>
  <v-card class=" ma-0" color="#121831" fluid>
    <div class="text-center outlet-details light-blue darken-2 py-3">
      <div class="d-flex px-3 justify-end">
        <v-icon class="header-close white--text" @click="close">mdi-close</v-icon>
      </div>
      <!-- <v-btn v-if="user.is_si_player" depressed class="mt-4 mb-2 " color="green darken-1" @click.stop="$store.dispatch('showWalletModal')">
        Add Credits from SI
      </v-btn> -->
    </div>
    <v-card color="#121831" tile elavation="0" class="pt-2 px-3 pb-3 text-center">
      <span class="grey--text font-weight-bold">Available Credits</span>
      <h1 class="amber--text text--lighten-1  d-block " v-html="formatMoney(user.credits)"></h1>
      <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              class="mb-0 wallet-form"
              @submit="submit()"
      >
        <v-text-field
                solo
                dense
                light
                type="number"
                v-model="withdraw.mobile"
                placeholder="Phone Number"
                label="Recipient"
                class="pt-3"
                :loading="busy"
                :rules="[rules.required, rules.phone]"
                :error-messages="error && error.mobile ? error.mobile[0] : ''"
                append-icon="mdi-notebook-outline"
                @click:append="recentModal = true"
                required
        />
        <v-text-field
                solo
                dense
                light
                type="number"
                v-model="withdraw.amount"
                label="Amount"
                placeholder="0"
                :loading="busy"
                :rules="[rules.required, creditsRule]"
                :error-messages="error && error.amount ? error.amount[0] : ''"
                required
        />
        <v-btn block color="light-blue darken-3" class="my-2" :loading="busy" @click="submit()">Transfer</v-btn>
      </v-form>
    </v-card>
    <v-dialog light v-model="recentModal" max-width="300">
      <v-card>
        <div class="bet-modal-header d-flex py-2 px-3 elevation-0">
          <strong>Recent Downlines</strong>
          <v-spacer></v-spacer>
          <v-icon class="header-close" @click="recentModal = false">mdi-close</v-icon>
        </div>
        <v-divider></v-divider>
        <v-list>
          <v-list-item
                  class="recent-list"
                  v-for="(rec, index) in recent"
                  :key="index"
                  @click.stop="withdraw.mobile = rec.mobile; recentModal = false"
          >
            {{ rec.mobile }} / {{ rec.name }}
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <wallet />
  </v-card>
</template>

<script>
  import mixin from "./../../mixins";
  import rules from "./../../mixins/rules";
  import helpers from "./../../mixins/helpers";

  import Wallet from "./../../modals/Wallet"

  export default {
    mixins: [mixin, rules, helpers],
    props: ['downlineMobile'],
    data: () => ({
      selected: null,
      withdraw: {
        amount: null,
        mobile: null
      },
      recent: [],
      recentModal: false,
      valid: true
    }),
    components: {
      Wallet
    },
    computed: {
      user() {
        return this.$store.state.user;
      },
      creditsRule() {
        return (
                this.withdraw.amount <= parseInt(this.user.credits) ||
                `You only have ${this.formatMoney(this.user.credits)} credits`
        );
      }
    },
    methods: {
      close(){
        this.$emit('close')
      },
      submit() {
        if (this.$refs.form.validate()) {
          this.postData(`/reseller/transfers`, this.withdraw, () => {
            this.$store.dispatch("snackbar", {
              message: "Credits successfully sent.",
              color: "success"
            });
            this.reset();
          });
        }
      }
    },
    mounted() {
      this.$nextTick(() => {
        if (this.$route.params.number) this.withdraw.mobile = this.$route.params.number;
        if (this.downlineMobile) this.withdraw.mobile = this.downlineMobile
      })

      this.getRecent(res => {
        this.recent = res.data
      })
    }
  };
</script>

<style lang="sass">
  .recent-list
    border-bottom: 1px solid #f8f8f8
    &:last-child
      border-bottom: none
</style>
