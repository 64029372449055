<template>
  <v-app id="inspire" >
    <nav-bar
      v-if="inApp && showNav" 
      show-drawer="drawer"
      @logout="logout()"
    >
      <template v-slot:reload>
        <v-progress-circular
          v-if="loading || initReload"
          class="loading-circle"
          size="24"
          indeterminate
          color="orange"
        />
        <v-icon
          v-else
          @click.stop.prevent="reload()"
        >
          mdi-cached
        </v-icon>
      </template>
    </nav-bar>
    <v-content :class="{ 'fab-bottom-padding' : inApp, 'page-content' : subAppView }">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
      <snackbar />
      <prompt />
    </v-content>
    <fab />
    <QRCode />
    <CommRate />
    <overlay />
  </v-app>
</template>

<script>
import routes from './mixins/routes'
import data from './mixins/data'
import fetchers from './mixins/fetchers'
import global from './mixins/global'
import pusher from './mixins/pusher'
import NavBar from './components/NavBar.vue'
import Snackbar from './components/Snackbar.vue'
import Overlay from './components/Overlay.vue'
import Fab from './components/Fab.vue'
import Prompt from './modals/Prompt.vue'
import QRCode from './modals/QRCode.vue'
import CommRate from './modals/CommRateModal.vue'

export default {
  mixins: [
    routes, 
    data, 
    fetchers, 
    global, 
    pusher
  ],
  components: {
    NavBar,
    Snackbar,
    Overlay,
    Fab,
    Prompt,
    CommRate,
    QRCode
  },
}
</script>

<style lang="sass">
@import './sass/colors.scss'

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button
  -webkit-appearance: none
  apperance: none
  margin: 0

h3
  font-weight: 400
  font-size: 1.1em

.main-snackbar
  margin-top: 48px !important

.height-100
  min-height: 100vh

.circle
  height: 12px
  width: 12px
  background-color: #272727
  border-radius: 50%
  display: inline-block

.page-content
  background: $main-background

.auth-cont
    background: linear-gradient(200deg, #1E88E5, #113d66)

.text-red
  color: $side-red-color

.text-blue
  color: $side-blue-color

.text-jackpot
  color: $side-jackpot-color

.red-empty
  border: 2px solid $side-red-color

.blue-empty
  border: 2px solid $side-blue-color

.jackpot-empty
  border: 2px solid $side-jackpot-color

.text-lose
  color: #828282

.clear
  display: block
  float: none
  clear: both

.border-radius-0
  border-radius: 0

.p-relative
  position: relative !important

.no-underline
  text-decoration: none !important

.fab-bottom-padding
  padding-bottom: 60px !important

.v-speed-dial__list
  padding: 0 6px !important
  .v-btn
    border: 2px solid orange

.fab-btn
  overflow: hidden
  img, .v-btn__content
    width: 100% !important

//Auth: Login, Registration, Verify, Password Reset

.main-form
    margin: 0 auto
    width: 100%
    max-width: 360px
    padding-right: 30px
    padding-left: 30px
    h4
      font-weight: 400

.login-logo
  max-width: 200px
  margin: 0 auto 20px

.auth-note
  font-size: .85em !important
  color: gray

.reg-logo
  max-width: 220px !important
  margin: 0 auto 10px

.terms
  font-size: .9em
  h3
    margin-bottom: 1em
    font-weight: 600

.terms-link
  color: #b5b5b5 !important

.error--text
  .terms-link
    color: #ff5252 !important

//Wallet: Deposit, Withdraw

.outlet-details
  font-size: .96em
  line-height: 1.5em
  h3
    font-weight: 600
  .phone-number
    letter-spacing: 0.1em
  .amount
    color: #ffca34 !important
    display: inline
  .outlet-icon
    width: 64px
    height: 56px
    margin: -40px auto 16px auto
    border-radius: 4px
    overflow: hidden
    img
      width: 100%
  .small
    font-size: .92em
  .instructions
    font-size: .92em
    border-bottom: 1px solid rgba(255,255,255,0.3)
    padding: 1em 0 1em
  .instructions:first-child
    padding-top: 0 !important
  .instructions:last-child
    padding-bottom: 0
    border-bottom: none

.notes
  background: #bbdefb !important
  color: #0d47a1 !important
  font-size: .9em

.wallet-form
  .v-input
    margin-bottom: .8em !important

.list-history
  font-size: 1em
  .with-amount
    font-size: 1em
  .with-status 
    font-size: .8em
  .with-info
    font-size: .8em
  .dep-info
    opacity: .8
  a
    text-decoration: none

.cancelled-status
  color: red

.processing-status, .refunded-status
  color: #FFB300

.paid-status, completed-status
  color: #7CB342

</style>
