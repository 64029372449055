import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        knownRoutes: [
            'home',
            'send',
            'send-credits',
            'withdrawals',
            'history',
            'commission',
            'downlines',
            'dashboard',
            'settings'
        ],
        exemptRoutes: [
            'login',
            'forgot-password',
            'new-password'
            // 'register',
            // 'verify'
        ],
        user: {
            id: null,
            name: null,
            mobile: null,
            mobile_verified: null,
            credits: 0,
            is_stream_blocked: false,
            is_blocked: false
        },
        snackbar: {
            show: false,
            message: null,
            timeout: 5000,
            color: 'green darken-2'
        },
        overlay: {
            show: false,
            button: true,
            message: null,
            type: null
        },
        prompt: {
            show: false,
            message: null,
            type: null
        },
        walletModal: {
            show: false
        },
        qrModal: {
            show: false
        },
        commRateModal: {
            show: false
        },
        getCommRateId: null,
        appEvent: null,
        loading: false,
        responsive: false
    },
    mutations: {
        SET_USER(state, payload) {
            state.user = payload
        },
        SET_WALLET_MODAL(state, payload) {
            state.walletModal.show = payload
        },
        SET_QR_MODAL(state, payload) {
            state.qrModal.show = payload
        },
        SET_COMM_RATE_MODAL(state, payload) {
            state.commRateModal.show = payload
        },
        SET_GET_COMM_RATE(state, payload) {
            state.getCommRateId = payload
        },
        SET_LOADING(state, payload) {
            state.loading = payload
        },
        SET_RESPONSIVE(state, payload) {
            state.responsive = payload
        },
        SET_SNACKBAR(state, payload) {
            if (payload.message) {
                state.snackbar.message = payload.message
                state.snackbar.show = true
            }

            payload.color ? state.snackbar.color = payload.color : state.snackbar.color = 'success'
        },
        SET_SNACKBAR_HIDE(state) {
            state.snackbar.show = false
        },
        SET_OVERLAY(state, payload) {
            if (payload.message) {
                state.overlay.message = payload.message
                state.overlay.button = payload.button
                if (payload.type) state.overlay.type = payload.type
                state.overlay.show = true
            }
        },
        SET_OVERLAY_HIDE(state) {
            state.overlay.show = false
        },
        SET_PROMPT(state, payload) {
            if (payload.message) {
                state.prompt.message = payload.message
                state.prompt.show = true
            }
        },
        SET_PROMPT_HIDE(state) {
            state.prompt.show = false
        },
        SET_APP_EVENT(state, payload) {
            state.appEvent = payload
        },
    },
    actions: {
        setUser({ commit }, payload) {
            commit('SET_USER', payload)
        },
        showWalletModal({ commit }) {
            commit('SET_WALLET_MODAL', true)
        },
        hideWalletModal({ commit }) {
            commit('SET_WALLET_MODAL', false)
        },
        showQRModal({ commit }) {
            commit('SET_QR_MODAL', true)
        },
        hideQRModal({ commit }) {
            commit('SET_QR_MODAL', false)
        },
        showCommRateModal({ commit }, payload) {
            commit('SET_GET_COMM_RATE', payload)
            commit('SET_COMM_RATE_MODAL', true)
        },
        hideCommRateModal({ commit }) {
            commit('SET_COMM_RATE_MODAL', false)
        },
        loading({ commit }, payload) {
            commit('SET_LOADING', payload)
        },
        responsive({ commit }, payload) {
            commit('SET_RESPONSIVE', payload)
        },
        snackbar({ commit }, payload) {
            commit('SET_SNACKBAR', payload)
        },
        snackbarHide({ commit }) {
            commit('SET_SNACKBAR_HIDE')
        },
        overlay({ commit }, payload) {
            commit('SET_OVERLAY', payload)
        },
        overlayHide({ commit }) {
            commit('SET_OVERLAY_HIDE')
        },
        win({ commit }, payload) {
            commit('SET_WIN', payload)
        },
        winHide({ commit }) {
            commit('SET_WIN_HIDE')
        },
        prompt({ commit }, payload) {
            commit('SET_PROMPT', payload)
        },
        promptHide({ commit }) {
            commit('SET_PROMPT_HIDE')
        },
        appEvent({ commit }, payload) {
            commit('SET_APP_EVENT', payload)
        }
    }
})
