<template>
  <v-card class="ma-0 pb-4" color="#121831" fluid>
    <div class="mb-4 mt-1 text-center outlet-details light-blue darken-2 pa-3 d-flex justify-end">
      <v-icon class="header-close white--text" @click="close">mdi-close</v-icon>
    </div>
    <update-name  class="mx-3 "/>
    <update-password  class="mx-3 "/>
  </v-card>
</template>

<script>
  import mixin from "./../mixins";
  import rules from "./../mixins/rules";
  import helpers from "./../mixins/helpers";

  export default {
    mixins: [mixin, rules, helpers],
    data: () => ({
      update: {
        name: null,
        valid: true
      },
      credentials: {
        old_password: null,
        new_password: null,
        confirm_password: null,
        valid: true
      },
      show1: false,
      show2: false,
      show3: false
    }),
    methods: {
        close() {
            this.$emit('close')
        }
    },
    components: {
      UpdateName: () => import("./../sections/UpdateName"),
      UpdatePassword: () => import("./../sections/UpdatePassword")
    }
  };
</script>

<style lang="sass" scoped>
  .sheet-form
    background: #2e3349
</style>
