<template>
  <div>
    <v-navigation-drawer temporary right v-model="drawer" app dense clipped color="#121831">
      <v-list v-if="user" class="ml-2">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">{{ user.name || null }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.mobile || null }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item link @click="goTo('/send')">
          <v-list-item-action>
            <v-icon>mdi-telegram</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Send Credits</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="goTo('/commission')">
          <v-list-item-action>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Commissions</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="goTo('/history')">
          <v-list-item-action>
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>History</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="goTo('/withdrawals')">
          <v-list-item-action>
            <v-icon>mdi-download</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Withdrawals</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-subheader class="ml-2">ACCOUNT</v-subheader>
        <v-list-item link @click="goTo('/settings')">
          <v-list-item-action>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="logout()">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar class="justify-space-between " color="light-blue darken-3 px-0 px-sm-8" height="55" elevation="0" app>
      <v-flex class="d-flex justify-start  ">
        <slot name="reload"></slot>
      </v-flex>
      <v-flex class="float d-flex justify-center ">
        <div  class="toolbar-logo ml-1" @click="reload()">
          <img src="./../assets/cfuLogo.png" height="40" />
        </div>
      </v-flex>
      <v-flex class="d-flex justify-end mr-n3 sm-mr-0">
        <v-icon link @click="logout()" class="mx-4">mdi-logout</v-icon>
<!--        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />-->
      </v-flex>
    </v-app-bar>
  </div>
</template>

<script>
import helpers from "./../mixins/helpers";

export default {
  mixins: [helpers],
  props: ["title", "showDrawer"],
  data: () => ({
    drawer: null
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
    showLogo() {
      return window.SETTINGS.SHOW_LOGO;
    }
  },
  methods: {
    goTo(path) {
      this.$router.push({ path: path }).catch(() => {
        this.drawer = false;
      });
    },
    logout() {
      this.drawer = false;
      this.$emit("logout");
    },
    reload() {
      window.location.reload();
    }
  },
  watch: {
    showDrawer() {
      this.drawer = this.showDrawer;
    }
  }
};
</script>

<style lang="sass">
.v-toolbar__title
  font-size: 1rem !important
  line-height: 1rem !important

.toolbar-logo
  margin-top: 7px
  overflow: hidden

@media screen and (max-width: 374px)
  .toolbar-logo
    width: 36px

#toolbar-title
  font-size: .85em

#credits
  font-size: .9em
  color: #ffca34
  h3
    font-weight: 700
</style>
