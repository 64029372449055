<template>
  <v-snackbar
    class="main-snackbar"
    :color="snackbar.color"
    v-model="snackbar.show"
    top
    :timeout="3000"
  >
    {{ snackbar.message }}
    <v-btn icon @click="$store.dispatch('snackbarHide')">
      <v-icon small>mdi-close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    snackbar: {
      get() {
        return this.$store.state.snackbar;
      },
      set(val) {
        val || this.$store.dispatch("snackbarHide");
      }
    }
  }
};
</script>