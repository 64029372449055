import Vue from 'vue';
import Router from 'vue-router';

// user
// import SendCredits from './views/reseller/SendCredits.vue'
// import History from './views/reseller/History.vue'
// import Commission from './views/reseller/Commission.vue'
// import Downlines from './views/reseller/Downlines.vue'
// import Withdrawals from './views/reseller/Withdrawals.vue'
// import Settings from './views/Settings.vue'
import Login from './views/Login.vue';
import ForgotPassword from './views/ForgotPassword.vue';
import NotFound from './views/NotFound.vue';
// import Register from './views/Register2.vue'
// import Verify from './views/Verify.vue'

import Dashboard from './views/reseller/Dashboard.vue';

Vue.use(Router)

export default new Router({
    // mode: process.env.CORDOVA_PLATFORM ? 'hash' : 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'dashboard',
            component: Dashboard,
        },
        // {
        //     path: '/commission',
        //     name: 'commission',
        //     component: Commission,
        // },
        // {
        //     path: '/send',
        //     name: 'send',
        //     component: SendCredits,
        // },
        // {
        //     path: '/send/:number',
        //     name: 'send-credits',
        //     component: SendCredits
        // },
        // {
        //     path: '/history',
        //     name: 'history',
        //     component: History
        // },
        // {
        //     path: '/commission',
        //     name: 'commission',
        //     component: Commission
        // },
        // {
        //     path: '/downlines',
        //     name: 'downlines',
        //     component: Downlines
        // },
        // {
        //     path: '/withdrawals',
        //     name: 'withdrawals',
        //     component: Withdrawals
        // },
        // {
        //     path: '/settings',
        //     name: 'settings',
        //     component: Settings
        // },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        // {
        //     path: '/register',
        //     name: 'register',
        //     component: Register
        // },
        // {
        //     path: '/verify',
        //     name: 'verify',
        //     component: Verify
        // },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: ForgotPassword
        },
        {
            path: '/forgot-password/:status',
            name: 'new-password',
            component: ForgotPassword
        },
        {
            path: '*',
            component: NotFound
        }
    ]
})
